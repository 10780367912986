import { useRef, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import scrollama from 'scrollama';

import './TravelStory.css';

export default function TravelStory(props) {
  const mapbox = useSelector((state) => state.mapbox);
  mapboxgl.accessToken = mapbox.mapbox;

  let params = useParams();
  let navigate = useNavigate();
  const mapContainer = useRef(null);
  const map = useRef(null);

  const filteredTravelData = mapbox.travelData.filter(
    (travel) => travel.slug === params.slug
  );
  const storyConfig = filteredTravelData[0].storyConfig;
  const chapterStart = storyConfig.chapters[0];

  useEffect(() => {
    if (map.current) return; // initialize map only once

    window.scrollTo(0, 0);

    const layerTypes = {
      fill: ['fill-opacity'],
      line: ['line-opacity'],
      circle: ['circle-opacity', 'circle-stroke-opacity'],
      symbol: ['icon-opacity', 'text-opacity'],
      raster: ['raster-opacity'],
      'fill-extrusion': ['fill-extrusion-opacity'],
    };

    const transformRequest = (url) => {
      const hasQuery = url.indexOf('?') !== -1;
      const suffix = hasQuery
        ? '&pluginName=journalismScrollytelling'
        : '?pluginName=journalismScrollytelling';
      return {
        url: url + suffix,
      };
    };

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: storyConfig.style,
      center: [
        chapterStart.location.center.latitude,
        chapterStart.location.center.longitude,
      ],
      zoom: chapterStart.location.zoom,
      bearing: chapterStart.location.bearing,
      pitch: chapterStart.location.pitch,
      scrollZoom: false,
      interactive: false,
      transformRequest: transformRequest,
    })
      .addControl(new mapboxgl.NavigationControl())
      .addControl(new mapboxgl.FullscreenControl());

    function getLayerPaintType(layer) {
      const layerType = map.current.getLayer(layer).type;
      return layerTypes[layerType];
    }

    function setLayerOpacity(layer) {
      const paintProps = getLayerPaintType(layer.layer);
      paintProps.forEach(function (prop) {
        map.current.setPaintProperty(layer.layer, prop, layer.opacity);
      });
    }

    const marker = new mapboxgl.Marker();
    if (storyConfig.showMarkers) {
      marker
        .setLngLat([
          chapterStart.location.center.latitude,
          chapterStart.location.center.longitude,
        ])
        .addTo(map.current);
    }

    // instantiate the scrollama
    const scroller = scrollama();

    map.current.on('load', () => {
      map.current.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14,
      });
      // add the DEM source as a terrain layer with exaggerated height
      map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });

      // add a sky layer that will show when the map is highly pitched
      map.current.addLayer({
        id: 'sky',
        type: 'sky',
        paint: {
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [0.0, 0.0],
          'sky-atmosphere-sun-intensity': 15,
        },
      });

      // Insert the layer beneath any symbol layer.
      var layers = map.current.getStyle().layers;
      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      map.current.addLayer(
        {
          id: 'add-3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 15,
          paint: {
            'fill-extrusion-color': '#aaa',

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'height'],
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height'],
            ],
            'fill-extrusion-opacity': 0.6,
          },
        },

        labelLayerId
      );

      // setup the instance, pass callback functions
      scroller
        .setup({
          step: '.step',
          offset: 0.5,
          progress: true,
        })
        .onStepEnter((response) => {
          const chapter = storyConfig.chapters.find(
            (chap) => chap.id === response.element.id
          );
          response.element.classList.add('active');
          map.current.flyTo({
            ...chapter.location,
            center: [
              chapter.location.center.latitude,
              chapter.location.center.longitude,
            ],
          });
          if (storyConfig.showMarkers) {
            marker.setLngLat([
              chapter.location.center.latitude,
              chapter.location.center.longitude,
            ]);
          }
          if (chapter.onChapterEnter.length > 0) {
            chapter.onChapterEnter.forEach(setLayerOpacity);
          }
        })
        .onStepExit((response) => {
          var chapter = storyConfig.chapters.find(
            (chap) => chap.id === response.element.id
          );
          response.element.classList.remove('active');
          if (chapter.onChapterExit.length > 0) {
            chapter.onChapterExit.forEach(setLayerOpacity);
          }
        });
    });

    // setup resize event
    window.addEventListener('resize', scroller.resize);
  });

  let features = filteredTravelData[0].storyConfig.chapters.map(
    (record, idx) => {
      const imageUrl = `${process.env.REACT_APP_BACKEND_PUBLIC_FILE_STORAGE_FOLDER}/introduction/mapbox/${filteredTravelData[0].slug}/${record.image}`;
      let img = record.image ? (
        <img
          src={imageUrl}
          alt={`${record.title} img`}
          style={{ cursor: 'pointer' }}
          onClick={() => window.open(imageUrl)}
        />
      ) : null;

      return (
        <div
          key={idx}
          id={record.id}
          className={`step ${idx === 0 ? 'active' : null}`}
        >
          <div className={storyConfig.theme}>
            <h3>{record.title}</h3>
            {img}
            <p>{record.description}</p>
          </div>
        </div>
      );
    }
  );

  return (
    <Fragment>
      <div
        className='travelStoryClose'
        onClick={() => {
          navigate(`/travel`);
          //document.getElementById('travel').scrollIntoView();
          // window.scrollTo({
          //  top: 5850, //document.getElementById('travel').offsetTop
          //  left: 0,
          //  behavior: 'auto',
          //});
        }}
      >
        World map
      </div>
      <div ref={mapContainer} id='map' className='map-container'></div>
      <div id='story'>
        <div id='storyHeader' className='dark'>
          <h1>{storyConfig.title}</h1>
          <h2>{storyConfig.subtitle}</h2>
          <p>{storyConfig.byline}</p>
        </div>
        <div
          id='features'
          className={
            {
              left: 'lefty',
              center: 'centered',
              right: 'righty',
            }[storyConfig.alignment]
          }
        >
          {features}
        </div>
      </div>
    </Fragment>
  );
}
