import './ActivityIndicator.css';

export default function ActivityIndicator() {
  return (
    <div className={'center'}>
      <div className={'loader'}>
        <div />
      </div>
    </div>
  );
}
