import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import './WorldMap.css';

export default function WorldMap() {
  const mapbox = useSelector((state) => state.mapbox);
  mapboxgl.accessToken = mapbox.mapbox;

  let navigate = useNavigate();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(18.2233);
  const [lat, setLat] = useState(46.0389);
  const [zoom, setZoom] = useState(10);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      scrollZoom: false,
      interactive: false,
      center: [lng, lat],
      zoom: zoom, //Default 1.9, but for zoom out effect when extending the bounds i use 10
    }); //.addControl(new mapboxgl.NavigationControl());

    let mapData = {
      geojson: {
        type: 'FeatureCollection',
        features: [],
      },
      coordinates: [],
    };

    mapbox.travelData.forEach((travel) => {
      mapData.geojson.features.push({
        type: 'Feature',
        properties: {
          description: travel.locationName,
          slug: travel.slug,
          icon: 'marker',
          iconSize: [25, 25],
          storyConfig: travel.storyConfig,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            travel.coordinates.latitude,
            travel.coordinates.longitude,
          ],
        },
      });
      //Extend map bounds to include current location
      mapData.coordinates.push([
        travel.coordinates.latitude,
        travel.coordinates.longitude,
      ]);
    });
    //Extend map bounds to include current location
    const bounds = mapData.coordinates.reduce(function (bounds, coord) {
      return bounds.extend(coord);
    }, new mapboxgl.LngLatBounds(
      mapData.coordinates[0],
      mapData.coordinates[0]
    ));
    map.current.fitBounds(bounds, {
      padding: {
        top: 220,
        bottom: 320,
        left: 320,
        right: 330,
      },
    });
    map.current.on('load', () => {
      map.current.addSource('places', {
        type: 'geojson',
        data: mapData.geojson,
      });
      // Add a layer showing the places.
      /* map.current.addLayer({
        id: 'places',
        type: 'symbol',
        source: 'places',
        layout: {
          'icon-image': '{icon}-15',
          'icon-allow-overlap': true,
        },
      }); */
    });

    // add markers to map
    mapData.geojson.features.map((marker) => {
      // create a DOM element for the marker
      let el = document.createElement('div');
      //https://ezgif.com/
      el.style.backgroundImage =
        marker.properties.storyConfig !== null
          ? `url(${process.env.REACT_APP_BACKEND_PUBLIC_FILE_STORAGE_FOLDER}/introduction/mapbox/map-pointer-red.gif)`
          : `url(${process.env.REACT_APP_BACKEND_PUBLIC_FILE_STORAGE_FOLDER}/introduction/mapbox/map-pointer-white.gif)`;
      el.style.width = marker.properties.iconSize[0] + 'px';
      el.style.height = marker.properties.iconSize[1] + 'px';

      if (marker.properties.storyConfig !== null) {
        el.addEventListener('click', (e) => {
          navigate(`/travel/${marker.properties.slug}`);
        });
        el.style.cursor = 'pointer';
      }

      // add marker to map
      return new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .addTo(map.current);
    });
  });

  useEffect(() => {
    let isMounted = true;

    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      if (isMounted) {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
      }
    });

    return () => {
      isMounted = false;
    };
  });

  return (
    <div>
      <div className='sidebar'>
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div>
      <div ref={mapContainer} className='map-container' />
    </div>
  );
}
