import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ActivityIndicator from '../components/ActivityIndicator/ActivityIndicator';
import WorldMap from '../containers/WorldMap';
import TravelStory from '../containers/TravelStory/TravelStory';
import NotFound from '../components/NotFound';

import { setMapbox, setTravelData } from '../store/mapbox-actions';

export default function Router() {
  const mapbox = useSelector((state) => state.mapbox);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setMapbox());
    dispatch(setTravelData());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to={'travel'} />} />
        <Route
          path='travel'
          element={
            mapbox.loading || mapbox.travelLoading ? (
              <ActivityIndicator />
            ) : (
              <WorldMap />
            )
          }
        />
        <Route
          path='travel/:slug'
          element={
            mapbox.loading || mapbox.travelLoading ? (
              <ActivityIndicator />
            ) : (
              <TravelStory />
            )
          }
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
