import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  mapbox: '',
  travelLoading: true,
  travelData: null,
  error: null,
};

export const mapboxSlice = createSlice({
  name: 'mapbox',
  initialState,
  reducers: {
    loading(state, action) {
      state.loading = action.payload;
    },
    travelLoading(state, action) {
      state.travelLoading = action.payload;
    },
    setMapbox: (state, action) => {
      state.mapbox = action.payload;
    },
    setTravelData: (state, action) => {
      state.travelData = action.payload;
    },
    setError(state, acton) {
      state.error = acton.payload;
    },
    errorConfirmation(state) {
      state.error = null;
    },
  },
});

export const mapboxActions = mapboxSlice.actions;
