import { httpRequest } from '../utils/httpRequest';
import { mapboxActions } from './mapbox-slice';
import { AppDispatch } from './index';

export const setMapbox = () => {
  return async (dispatch: AppDispatch) => {
    await dispatch(mapboxActions.loading(true));

    try {
      const graphqlQuery = {
        query: `
        query GetAccessToken($API_name: String!){
          getAccessToken(API_name: $API_name) {
            __typename
            ... on AccessTokenData { API_name, token, createdAt }
            ... on Error { code, message }
        }
      }
      `,
        variables: {
          API_name: 'mapbox',
        },
      };

      const responseData = await httpRequest({
        url: process.env.REACT_APP_GRAPHQL_BACKEND_ADDRESS
          ? process.env.REACT_APP_GRAPHQL_BACKEND_ADDRESS
          : '',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      if (responseData.getAccessToken.code) {
        return dispatch(
          mapboxActions.setError(responseData.getAccessToken.message)
        );
      } else {
        dispatch(mapboxActions.setMapbox(responseData.getAccessToken.token));
      }
      await dispatch(mapboxActions.loading(false));
    } catch (err) {
      dispatch(mapboxActions.loading(false));
      return dispatch(mapboxActions.setError(err));
    }
  };
};

export const setTravelData = (locationNameSlug?: string) => {
  return async (dispatch: AppDispatch) => {
    await dispatch(mapboxActions.travelLoading(true));

    try {
      const graphqlQuery = {
        query: `
        query GetTravel ( $slug: String ){
          getTravel (slug: $slug) {
              __typename
              ... on TravelData {
                  travelId
                  locationName
                  slug
                  coordinates {
                      latitude
                      longitude
                  }
                  storyConfig {
                      style
                      showMarkers
                      theme
                      alignment
                      title
                      subtitle
                      byline
                      chapters {
                          id
                          title
                          image
                          description
                          location {
                              center {
                                  latitude
                                  longitude
                              }
                              zoom
                              pitch
                              bearing
                          }
                          onChapterEnter
                          onChapterExit
                      }
                  }
              }
              ... on Error {
                  code,
                  message
              }
          }
        }
      `,
        variables: {
          slug: locationNameSlug,
        },
      };

      const responseData = await httpRequest({
        url: process.env.REACT_APP_GRAPHQL_BACKEND_ADDRESS
          ? process.env.REACT_APP_GRAPHQL_BACKEND_ADDRESS
          : '',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: graphqlQuery,
      });

      if (responseData.getTravel[0].code) {
        return dispatch(
          mapboxActions.setError(responseData.getTravel[0].message)
        );
      } else {
        dispatch(mapboxActions.setTravelData(responseData.getTravel));
      }
      await dispatch(mapboxActions.travelLoading(false));
    } catch (err) {
      dispatch(mapboxActions.travelLoading(false));
      return dispatch(mapboxActions.setError(err));
    }
  };
};
